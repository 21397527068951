// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3e42a23f_toolbar{display:flex;flex-direction:row;justify-content:flex-end;width:100%;align-items:center}._3e42a23f_toolbar_left{justify-content:flex-start;display:flex;margin-right:auto}._3e42a23f_toolbar__empty-margin{margin:0}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/toolbar/UiToolbar.scss"],"names":[],"mappings":"AACA,mBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,UAAA,CACA,kBAAA,CAEA,wBACE,0BAAA,CACA,YAAA,CACA,iBAAA,CAGF,iCACE,QAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.toolbar {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  width: 100%;\n  align-items: center;\n\n  &_left {\n    justify-content: flex-start;\n    display: flex;\n    margin-right: auto;\n  }\n\n  &__empty-margin {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": "_3e42a23f_toolbar",
	"toolbar_left": "_3e42a23f_toolbar_left",
	"toolbar__empty-margin": "_3e42a23f_toolbar__empty-margin",
	"toolbar__emptyMargin": "_3e42a23f_toolbar__empty-margin"
};
export default ___CSS_LOADER_EXPORT___;
