// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._74131ca6_ui-virtual-list{overflow:auto}._74131ca6_ui-virtual-list__list{transform:translateY(var(--list-translate-y, 0))}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/virtual-list/UiVirtualList.scss"],"names":[],"mappings":"AAOA,2BACE,aAAA,CAEA,iCACE,gDAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n$listTranslateY: --list-translate-y;\n\n:export {\n  listTranslateY: $listTranslateY;\n}\n\n.ui-virtual-list {\n  overflow: auto;\n\n  &__list {\n    transform: translateY(var(--list-translate-y, 0));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listTranslateY": "--list-translate-y",
	"ui-virtual-list": "_74131ca6_ui-virtual-list",
	"uiVirtualList": "_74131ca6_ui-virtual-list",
	"ui-virtual-list__list": "_74131ca6_ui-virtual-list__list",
	"uiVirtualList__list": "_74131ca6_ui-virtual-list__list"
};
export default ___CSS_LOADER_EXPORT___;
