import React, { HTMLAttributes, PropsWithChildren, ReactNode, useMemo } from 'react';

import { UiDnDUniqueIdentifier } from '../../dnd';
import { ColumnType, ColumnsType, UiTableProps } from '../UiTable';
import { UiTableDnDCell, UiTableDnDCellProps } from './cell/UiTableDnDCell';
import { UiTableDnDContextBody } from './context-body/UiTableDnDContextBody';
import { UiTableDnDContextHeader } from './context-header/UiTableDnDContextHeader';
import { UiTableDnDRow, UiTableDnDRowProps } from './row/UiTableDnDRow';

const DRAG_HANDLE_COLUMN_KEY = 'drag-handle-column';

export interface TableDnDConfigProps<RecordItem extends object = Record<string, unknown>>
  extends Pick<UiTableProps<RecordItem>, 'columns' | 'dataSource'> {
  onRowDragEnd?: (items: RecordItem[]) => void;
  onColumnDragEnd?: (items: ColumnsType<RecordItem>) => void;
  activatorColumnProps?: Omit<ColumnType<RecordItem>, 'key' | 'fixed'>;
  renderCellOverlay?: (params: {
    id: UiDnDUniqueIdentifier;
    column?: ColumnsType<RecordItem>[number];
  }) => ReactNode;
}

type HeaderDnDCellProps = UiTableDnDCellProps;
type BodyDnDRowProps = UiTableDnDRowProps;
type UiTableComponentProps = PropsWithChildren<HTMLAttributes<HTMLElement>>;

export const useTableDnDConfig = <T extends Record<string, unknown>>(params: TableDnDConfigProps<T>) => {
  const { columns, dataSource, onColumnDragEnd, onRowDragEnd, renderCellOverlay, activatorColumnProps } =
    params;
  const modifiedColumns = useMemo<UiTableProps<T>['columns']>(() => {
    const defaultColumnWidth = 60;
    const isRowDraggable = Boolean(onRowDragEnd || activatorColumnProps);

    const additionalColumns: ColumnsType<T> = [
      {
        key: DRAG_HANDLE_COLUMN_KEY,
        fixed: 'right',
        width: defaultColumnWidth,
        render: () => <UiTableDnDRow.DragButton />,
        ...activatorColumnProps,
      },
    ];

    return columns?.concat(isRowDraggable ? additionalColumns : [])?.map((column) => ({
      ...column,
      ...(column.key !== DRAG_HANDLE_COLUMN_KEY &&
        onColumnDragEnd && {
          onHeaderCell: () => ({ cellKey: column.key } as HeaderDnDCellProps),
        }),
    }));
  }, [onRowDragEnd, onColumnDragEnd, columns, activatorColumnProps]);

  return {
    modifiedColumns,
    ...(onColumnDragEnd && {
      HeaderDnD: (props: UiTableComponentProps) => (
        <UiTableDnDContextHeader
          {...props}
          {...{
            columns,
            onColumnDragEnd,
            renderCellOverlay,
          }}
        />
      ),
      HeaderDnDCell: ({ cellKey, ...props }: HeaderDnDCellProps) =>
        cellKey ? <UiTableDnDCell cellKey={cellKey} {...props} /> : <th {...props} />,
    }),
    ...(onRowDragEnd && {
      BodyDnDRow: (props: BodyDnDRowProps) => <UiTableDnDRow {...props} />,
      BodyDnD: (props: UiTableComponentProps) => (
        <UiTableDnDContextBody
          {...props}
          {...{
            columns,
            dataSource,
            onRowDragEnd,
          }}
        />
      ),
    }),
  };
};
