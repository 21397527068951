import { Image as AntImage } from 'antd';
import classNames from 'classnames';
import isObject from 'lodash/isObject';
import {
  PreviewGroupPreview as AntPreviewGroupPreview,
  GroupConsumerProps as AntGroupConsumerProps,
} from 'rc-image/lib/PreviewGroup';
import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import CloseSvg from '@vkph/ui/svg/close.svg';

import { UseFullscreenResult } from '../../../../hooks';
import { UiFullscreen } from '../../../fullscreen';
import { UiIcon } from '../../../icon';
import { UiImageProps } from '../../UiImage';
import styles from '../UiImagePreview.scss';
import {
  UiImageCustomContentPreview,
  UiImagePreviewGroupSiderProps,
  useImageCustomContentPreview,
} from '../custom-content';
import { UiImagePreviewToolbar, UiImagePreviewToolbarExtension, PREVIEW_TRANSFORM_SCALE } from '../toolbar';

export interface UiImagePreviewGroupPreviewExtended
  extends AntPreviewGroupPreview,
    UiImagePreviewToolbarExtension {
  sider?: UiImagePreviewGroupSiderProps;
  fullscreen?: UseFullscreenResult;
}

export interface UiImagePreviewGroupProps
  extends Omit<AntGroupConsumerProps, 'preview' | 'items'>,
    Pick<UiImageProps, 'wrapperStyle'> {
  preview: UiImagePreviewGroupPreviewExtended | true;
  items?: string[];
  isControlled?: boolean;
}
type UiImagePreviewGroupComponent = FC<PropsWithChildren<UiImagePreviewGroupProps>>;

export const UiImagePreviewGroup: UiImagePreviewGroupComponent = (props) => {
  const { preview, children, items, isControlled = true } = props;

  const {
    customize,
    visible,
    onChange,
    current,
    onVisibleChange,
    sider,
    toolbarRender,
    fullscreen,
    ...restPreviewConfig
  }: UiImagePreviewGroupPreviewExtended = isObject(preview) ? preview : {};

  const { ref, getContainer, containerElement, imageElement } = useImageCustomContentPreview();
  const { isEnabled, ref: fullscreenRef } = fullscreen || { ref: {} };

  const getDefaultToolbar: AntPreviewGroupPreview['toolbarRender'] = (_, { transform, actions }) => (
    <UiImagePreviewToolbar
      transform={transform}
      actions={actions}
      customize={customize}
      counter={items?.length ? `${(current ?? 0) + 1}/${items.length}` : '0/0'}
    />
  );

  const previewConfig = useMemo<UiImagePreviewGroupPreviewExtended>(() => {
    return {
      toolbarRender: toolbarRender || getDefaultToolbar,
      mask: <div style={{ cursor: 'pointer' }} />,
      scaleStep: PREVIEW_TRANSFORM_SCALE.SCALE_STEP,
      minScale: PREVIEW_TRANSFORM_SCALE.SCALE_MIN,
      maxScale: PREVIEW_TRANSFORM_SCALE.SCALE_MAX,
      closeIcon: <UiIcon width={20} height={20} component={CloseSvg} />,
      countRender: () => null,
      ...restPreviewConfig,
    };
  }, [current, sider, imageElement, isEnabled]);

  const AntImagePreviewGroup = (
    <AntImage.PreviewGroup
      preview={{
        rootClassName: classNames(
          styles.uiImagePreview,
          sider?.visible && styles.uiImagePreview_sidebarOpened_true,
          sider?.placement === 'left'
            ? styles.uiImagePreview_sidebarPosition_left
            : styles.uiImagePreview_sidebarPosition_right,
        ),
        getContainer: isEnabled ? fullscreenRef.current || false : getContainer,
        visible,
        onChange,
        current,
        onVisibleChange,
        ...(previewConfig as Record<string, unknown>),
      }}
      items={items}
    >
      {!isControlled && (
        <>
          {React.Children.map<ReactNode, ReactNode>(children, (child) => {
            if (React.isValidElement<UiImageProps>(child)) {
              return React.cloneElement(child, {});
            }

            return null;
          })}
        </>
      )}
    </AntImage.PreviewGroup>
  );

  if (fullscreen) {
    return (
      <UiFullscreen fullscreen={fullscreen}>
        {sider && (
          <UiImageCustomContentPreview
            ref={ref}
            parentElement={isEnabled ? fullscreenRef.current : containerElement}
            {...sider}
          >
            {AntImagePreviewGroup}
          </UiImageCustomContentPreview>
        )}
        {!sider && AntImagePreviewGroup}
      </UiFullscreen>
    );
  }

  if (sider) {
    return (
      <UiImageCustomContentPreview
        ref={ref}
        parentElement={isEnabled ? fullscreenRef.current : containerElement}
        {...sider}
      >
        {AntImagePreviewGroup}
      </UiImageCustomContentPreview>
    );
  }

  return AntImagePreviewGroup;
};
