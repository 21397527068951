import { Dropdown as AntDropdown, DropdownProps as AntDropdownProps } from 'antd';
import { DropdownButtonProps as AntDropdownButtonProps } from 'antd/lib/dropdown';
import classNames from 'classnames';
import React, { FC, MouseEventHandler, cloneElement, ReactElement } from 'react';

import DropDownSvg from '../../svg/drop-down.svg';
import { UiButtonProps } from '../button';
import { UiIcon } from '../icon';
import styles from './UiDropdown.scss';

export type UiDropdownProps = AntDropdownProps;

export interface UiDropdownButtonProps extends Omit<AntDropdownButtonProps, 'buttonsRender'> {
  mainButtonProps?: UiButtonProps;
  menuButtonProps?: UiButtonProps;
}

const UiDropdownButton: FC<UiDropdownButtonProps> = (props) => {
  const {
    className,
    onClick,
    icon = <UiIcon component={DropDownSvg} width={20} height={20} />,
    mainButtonProps,
    menuButtonProps,
    ...restProps
  } = props;

  const onClickStopPropagation: MouseEventHandler<HTMLBaseElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();

    onClick?.(e);
  };

  return (
    <AntDropdown.Button
      {...restProps}
      icon={icon}
      onClick={onClickStopPropagation}
      className={classNames(styles.uiDropdown__dropdownButton, className)}
      // @ts-expect-error Переопределил block для Space.Compact, через ...restProps
      block={false}
      buttonsRender={([mainButton, menuButton]) => [
        cloneElement(mainButton as ReactElement<UiButtonProps>, mainButtonProps),
        cloneElement(menuButton as ReactElement<UiButtonProps>, menuButtonProps),
      ]}
    />
  );
};

type UiDropdownComposition = {
  Button: typeof UiDropdownButton;
};

export const UiDropdown: FC<UiDropdownProps> & UiDropdownComposition = (props) => <AntDropdown {...props} />;

UiDropdown.Button = UiDropdownButton;
