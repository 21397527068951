import { Layout } from 'antd';
import classNames from 'classnames';
import React, { CSSProperties, FC, HTMLAttributes, PropsWithChildren } from 'react';

import { variables } from '../../../providers/theme';
import { useLayout } from '../hooks';
import styles from './LayoutContent.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  stretch?: boolean;
}

export const LayoutContent: FC<PropsWithChildren<Props>> = (props) => {
  const { children, stretch, style, className, ...rest } = props;
  const { innerGutter } = useLayout();

  const mergedStyle: CSSProperties = {
    gap: innerGutter,
    maxWidth: stretch ? '100%' : variables.layoutSizes.contentMaxWidth,
    ...style,
  };

  return (
    <Layout.Content style={mergedStyle} className={classNames(styles.layoutContent, className)} {...rest}>
      {children}
    </Layout.Content>
  );
};
