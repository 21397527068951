import React, { FC } from 'react';

import { useSpace } from '../../../../hooks';
import { UiButton, UiButtonProps } from '../../../button';
import { UiIcon, UiIconLabelProps } from '../../../icon';

const IMAGE_PREVIEW_BUTTON_PROPS: UiButtonProps = {
  type: 'link',
  disabledFocus: true,
};

interface Props extends Pick<UiButtonProps, 'onClick' | 'disabled'> {
  icon: UiIconLabelProps['component'];
  active?: UiIconLabelProps['active'];
}

export const UiImagePreviewButton: FC<Props> = (props) => {
  const { icon, active, onClick } = props;
  const { spaceS } = useSpace();

  return (
    <UiButton
      {...IMAGE_PREVIEW_BUTTON_PROPS}
      icon={<UiIcon.Label active={active} style={{ margin: spaceS }} component={icon} />}
      onClick={onClick}
    />
  );
};
