import { AutoComplete as AntAutoComplete, AutoCompleteProps, Input as AntInput } from 'antd';
import classNames from 'classnames';
import React, { ForwardRefRenderFunction, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import DropDownSvg from '@vkph/ui/svg/drop-down.svg';

import { UiFlex } from '../flex';
import { UiIcon } from '../icon';
import {
  UiBaseSelectRef,
  UiOptionData,
  UiRefSelectProps,
  UiSelectBaseOptionType,
  UiSelectMaxLengthProps,
} from '../select';
import { UiSpinner } from '../spinner';
import styles from './UiAutoComplete.scss';

export const AnimatedDropDownIcon = () => <UiIcon width={20} height={20} component={DropDownSvg} />;

// @ts-expect-error TODO: проверить работает ли maxLength, потому что раньше это был хак
export interface UiAutoCompleteProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ValueType = any,
  OptionType extends UiSelectBaseOptionType | UiOptionData = UiOptionData,
> extends AutoCompleteProps<ValueType, OptionType>,
    UiSelectMaxLengthProps {
  prefix?: ReactNode;
  placeholder?: ReactNode | string;
  debounce?: number | boolean;
  loading?: boolean;
}

const InternalUiAutoComplete: ForwardRefRenderFunction<UiRefSelectProps, UiAutoCompleteProps> = (
  props,
  ref,
) => {
  const {
    suffixIcon,
    placeholder,
    size,
    value,
    className,
    prefix,
    maxLength,
    popupClassName,
    debounce,
    loading,
    notFoundContent,
    ...restProps
  } = props;
  const { onChange, onSearch } = restProps;
  const suffix = suffixIcon !== undefined ? suffixIcon : <AnimatedDropDownIcon />;
  const [inputValue, setInputValue] = useState(value);

  const debounceTime = useMemo(() => {
    if (typeof debounce === 'boolean') {
      return debounce ? 500 : undefined;
    }

    return debounce;
  }, [debounce]);

  const placeholders = {
    input: typeof placeholder === 'string' ? placeholder : undefined,
    select: React.isValidElement(placeholder) ? placeholder : undefined,
  };

  const popupClassNameStyles = classNames(popupClassName, styles.uiAutoComplete__popup);

  const onSearchDebounced = useDebouncedCallback((val: string) => onSearch?.(val), debounceTime);

  const onChangeSearchDebounced = useDebouncedCallback((val: string) => {
    onChange?.(val, { value: val });
  }, debounceTime);

  const onChangeSearch = (val: string) => {
    setInputValue(val);
    onChangeSearchDebounced(val);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const emptyOrLoadingContent = useMemo(() => {
    if (loading) {
      return (
        <UiFlex justify="center">
          <UiSpinner size="default" spinning />
        </UiFlex>
      );
    }

    return notFoundContent;
  }, [loading]);

  return (
    <AntAutoComplete
      ref={ref}
      className={classNames(styles.uiAutoComplete, className)}
      placeholder={placeholders.select}
      // TODO: это единственный способ использовать maxLength в antd@4.20.3
      maxLength={maxLength}
      popupClassName={popupClassNameStyles}
      {...restProps}
      {...(debounceTime && {
        value: inputValue,
        onSearch: onSearchDebounced,
        onChange: onChangeSearch,
      })}
      {...(!debounceTime && {
        value,
      })}
      notFoundContent={emptyOrLoadingContent}
    >
      <AntInput
        value={debounceTime ? inputValue : value}
        size={size}
        placeholder={placeholders.input}
        suffix={suffix}
        prefix={prefix}
      />
    </AntAutoComplete>
  );
};

export const UiAutoComplete = React.forwardRef<UiRefSelectProps, UiAutoCompleteProps>(
  InternalUiAutoComplete,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) as unknown as (<ValueType = any, OptionType extends UiSelectBaseOptionType | UiOptionData = UiOptionData>(
  props: React.PropsWithChildren<UiAutoCompleteProps<ValueType, OptionType>> & {
    ref?: React.Ref<UiBaseSelectRef>;
  },
) => React.ReactElement) & {
  Option: typeof AntAutoComplete.Option;
};

UiAutoComplete.Option = AntAutoComplete.Option;
